import request from "@/utils/request";

// =============分类标签 start ===============================================================

/**
 * 查询级联标签
 * @param params
 */
export function getCascadeList(params) {
  return request({
    url: "/api/backend/artgoods/artCategory/getCascadeList",
    method: "get",
    params
  });
}

// =============分类标签 end ===============================================================

// =============艺术家 start ===============================================================

/**
 * 查询艺术家列表
 * @param params
 */
export function artistsGetList(params) {
  return request({
    url: "/api/backend/artgoods/artists/getList",
    method: "get",
    params
  });
}

// =============艺术家 end ===============================================================

// =============艺术品 start =============================================================

// =============艺术品 end ===============================================================

// =============收藏家 start ===============================================================

// =============收藏家 end ===============================================================
