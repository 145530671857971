<template>
  <el-select
    v-model="artistsId"
    :style="{ width: widthStyle }"
    class="filter-item"
    clearable
    filterable
    remote
    reserve-keyword
    placeholder="请输入姓名进行搜索"
    :remote-method="artistsRemoteMethod"
    :loading="artistsLoading"
    @change="artistsChange"
  >
    <el-option
      v-for="item in artistsOption"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    />
  </el-select>
</template>
<script>
import { artistsGetList } from "@/api/art";

export default {
  props: {
    isMultiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object
    },
    widthStyle: {
      type: String,
      default: "100%"
    },
    type: {
      type: String
    }
  },
  computed: {},
  components: {},
  filters: {},
  data() {
    return {
      artistsId: this.value, //
      artistsLoading: false, // 查询艺术家的搜索
      artistsOption: [] // 艺术家选项
    };
  },
  created() {
    this.artistsRemoteMethod("");
  },
  watch: {},
  methods: {
    /**
     * 收藏家的选项搜索
     * @param query
     */
    artistsRemoteMethod(query) {
      this.artistsLoading = true;
      const params = {
        page: 1,
        limit: 9999
      };
      if (query) {
        params.keyword = query;
      }
      artistsGetList(params)
        .then(resp => {
          this.artistsOption = resp.data.data || [];
        })
        .finally(() => {
          this.artistsLoading = false;
        });
    },
    artistsChange(value) {
      this.$emit("valueFunc", this.type, value);
    }
  }
};
</script>
<style scoped lang="scss"></style>
